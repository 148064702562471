<template>
    <div class="en_add">
        <el-card>
            <el-form ref="form" :model="form" :rules="rule" label-width="130px" :inline="false" size="normal">
                <el-form-item label="规则名称" prop="name">
                    <el-col :span="8">
                        <el-input v-model="form.name" show-word-limit disabled />
                    </el-col>
                </el-form-item>
                <template v-if="form.type == 'cooperation_top'">
                    <el-form-item label="大厅置顶">
                        <div
                            style="display: flex;margin-bottom: 10px;"
                            v-for="(item, index) in form.extendRule.top"
                            :key="index"
                        >
                            <div style="margin-right: 10px;">
                                <el-input v-model="item.day">
                                    <template slot="append">天</template>
                                </el-input>
                            </div>
                            <div style="margin-right: 10px;">
                                <el-input v-model="item.bonus">
                                    <template slot="append">积分</template>
                                </el-input>
                            </div>
                            <div>
                                <el-input v-model="item.coin">
                                    <template slot="append">帆币</template>
                                </el-input>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="模块置顶">
                        <div
                            style="display: flex;margin-bottom: 10px;"
                            v-for="(item, index) in form.extendRule.category_top"
                            :key="index"
                        >
                            <div :span="2" style="margin-right: 10px;">
                                <el-input v-model="item.day">
                                    <template slot="append">天</template>
                                </el-input>
                            </div>
                            <div style="margin-right: 10px;">
                                <el-input v-model="item.bonus">
                                    <template slot="append">积分</template>
                                </el-input>
                            </div>
                            <div>
                                <el-input v-model="item.coin">
                                    <template slot="append">帆币</template>
                                </el-input>
                            </div>
                        </div>
                    </el-form-item>
                </template>
                <template v-else-if="form.type == 'cooperation_color'">
                    <el-form-item label="任务规则">
                        <div
                            style="display: flex;margin-bottom: 10px;"
                            v-for="(item, index) in form.extendRule"
                            :key="index"
                        >
                            <div style="margin-right: 10px;">
                                <el-input v-model="item.day">
                                    <template slot="append">天</template>
                                </el-input>
                            </div>
                            <div>
                                <el-input v-model="item.coin">
                                    <template slot="append">帆币</template>
                                </el-input>
                            </div>
                        </div>
                    </el-form-item>
                </template>
                <template v-else-if="!(form.type == 'everyday_sign')">
                    <el-form-item label="积分" prop="bonus">
                        <el-col :span="8">
                            <el-input v-model="form.bonus" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="帆币" prop="bonus">
                        <el-col :span="8">
                            <el-input v-model="form.coin" />
                        </el-col>
                    </el-form-item>
                </template>
                <template v-if="form.type == 'invitation_reg'">
                    <el-form-item label="邀请海报">
                        <imgups :action="action" :list.sync="form.banner"></imgups>
                    </el-form-item>
                </template>
                <template v-if="form.type == 'everyday_sign'">
                    <el-form-item label="每日签到" prop="extendRule">
                        <br />
                        <div style="display:flex;margin-bottom: 10px;" v-for="(item, index) in dayList" :key="index">
                            <span style="margin-right: 10px;">{{ item.title }}</span>
                            <el-input v-model="day[item.name + '_day']" style="width: 300px;" />
                            <span style="width:40px;margin-left: 10px;">积分</span>
                        </div>
                    </el-form-item>
                </template>
                <template v-if="obtainIntegral">
                    <el-form-item label="每天获得最多积分" prop="day_limit_bonus">
                        <el-col :span="8">
                            <el-input v-model="day_limit_bonus.day_limit_bonus" />
                        </el-col>
                    </el-form-item>
                </template>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="onSubmit">{{ id ? "修改" : "提交" }}</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import imgup from "../../components/upImg/index.vue";
import rules from "../../utils/rules";
import imgups from "../../components/uplist/index.vue";
export default {
    components: {
        imgup,
        imgups,
    },
    data() {
        return {
            id: null,
            form: {
                name: "",
                picture: "",
                logo: "",
                desc: "",
                number: 0,
                bonus: "",
            },
            dayList: [
                {
                    name: "one",
                    title: "第一天",
                },
                {
                    name: "two",
                    title: "第二天",
                },
                {
                    name: "three",
                    title: "第三天",
                },
                {
                    name: "four",
                    title: "第四天",
                },
                {
                    name: "five",
                    title: "第五天",
                },
                {
                    name: "six",
                    title: "第六天",
                },
                {
                    name: "seven",
                    title: "第七天及以后",
                },
            ],
            day: {},
            rule: {
                name: [rules.req("请输入商品名称")],
                fullName: [rules.req("请输入企业全称")],
                logo: [rules.req("请输入企业logo")],
                city: [rules.req("请输入省份和城市")],
                people: [rules.req("请输入公司人事")],
                financing: [rules.req("请输入融资情况")],
                type: [rules.req("请输入类型")],
                business: [rules.req("请输入行业")],
                serverArea: [rules.req("请输入业务区域")],
                companyDesc: [rules.req("请输入企业介绍")],
                intro: [rules.req("请输入企业简介")],
            },
            action: {
                action: "bonus",
            },
            day_limit_bonus: {},
            province: {},
            city: {},
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
        };
    },
    watch: {
        day(newValue) {
            console.log(newValue);
        },
    },
    computed: {
        obtainIntegral() {
            return (
                this.form.type == "view_article" ||
                this.form.type == "article_share" ||
                this.form.type == "cooperation_share" ||
                this.form.type == "share" ||
                this.form.type == "view_report" ||
                this.form.type == "comment_article"
            );
        },
    },
    created() {
        sessionStorage.setItem("action", "bonus");
        this.getform();
    },
    methods: {
        change(val) {
            this.form.desc = val;
        },

        async onSubmit() {
            var url;
            if (this.id) {
                url = "/admin/TaskRule/edit";
            }
            if (this.form.type == "invitation_reg") {
                var arr = this.form.banner.map((item) => item.url);
                var form = {
                    ...this.form,
                    id: this.id,
                    extendRule: JSON.stringify({ poster: arr.toString() }),
                };
            } else if (this.form.type == "everyday_sign") {
                var form = {
                    ...this.form,
                    id: this.id,
                    extendRule: JSON.stringify(this.day),
                };
            } else if (this.hasExtendRule()) {
                var form = {
                    ...this.form,
                    id: this.id,
                    extendRule: JSON.stringify(this.form.extendRule),
                };
            } else if (this.obtainIntegral) {
                var form = {
                    ...this.form,
                    id: this.id,
                    extendRule: JSON.stringify(this.day_limit_bonus),
                };
            } else {
                var form = {
                    ...this.form,
                    id: this.id,
                };
            }
            // console.log(form.);
            var { data: res } = await this.$http.post(url, form);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$router.push("/task_rule_list");
            }
        },
        hasExtendRule() {
            return ["cooperation_color", "cooperation_top"].includes(this.form.type);
        },
        async getform() {
            if (!this.$route.query.id) {
                return;
            }
            this.id = this.$route.query.id;
            const { data: res } = await this.$http.get("/admin/TaskRule/getById?id=" + this.id);
            if (res.errorCode == 200) {
                this.form = { ...res.data, banner: [] };
                if (this.form.extendRule) {
                    if (this.form.type == "invitation_reg") {
                        var arr = JSON.parse(this.form.extendRule) && JSON.parse(this.form.extendRule).poster;
                        this.form.banner = arr.split(",").map((item) => {
                            return {
                                name: item,
                                url: item,
                            };
                        });
                    }
                    if (this.form.type == "everyday_sign") {
                        this.day = JSON.parse(this.form.extendRule);
                    } else if (this.obtainIntegral) {
                        this.day_limit_bonus = JSON.parse(this.form.extendRule);
                    }

                    if (this.hasExtendRule()) {
                        try {
                            this.form.extendRule = JSON.parse(this.form.extendRule);
                        } catch (error) {}
                    }
                }
            }
            //  this.form.initViews =
        },
    },
};
</script>

<style lang="less">
.en_add {
    .box {
        display: inline-block;
        width: 300px;
        input {
            border-color: #dcdfe6 !important;
        }
    }
}
</style>
